import React from 'react';
import Img from 'gatsby-image';
import get from 'lodash.get';
import { Link } from 'gatsby-plugin-react-intl';

import {
    SliderHero,
    NewSlider,
    SharedStyles,
    Container,
} from '../../kenra-storybook/index';

import { ConditionalWrapper } from '../ConditionalWrapper';

const { StLink } = SharedStyles;

const {
    StSliderHeroItem,
    StSliderHeroImg,
    StSliderHeroImgWeb,
    StSliderHeroImgMobile,
    StSliderHeroItemDescFirst,
    StSliderHeroItemDesc,
    StSliderHeroItemDescSecond,
    StSliderHeroItemDescWeb,
    StSliderHeroImgTextContainer,
    StSliderHeroSmallImgWeb,
    StSliderHeroItemSmallImgText,
    StSliderHeroImgText,
    StSliderHeroImgContainer,
    SlideWrapper,
    SliderCol,
    SliderImageIn,
} = SliderHero;

const swiperUseEffect = function(swiper) {
    const slides = swiper.slides;
    Object.values(slides).forEach(elem => {
        if (
            elem.querySelector &&
            elem.classList.contains('swiper-slide-duplicate')
        ) {
            if (elem.querySelector('picture > img')) {
                elem.querySelector('picture > img').style.opacity = 1;
            }
        }
    });
};

export default function HeroSlider({ slides, showText }) {
    // Go through each slide and remove the domain if it appears

    // Go through each slide and remove the domain if it appears
    slides.forEach(slide => {
        if (slide?.slideLink !== null && slide?.slideLink?.startsWith('http')) {
            slide.slideLink = slide.slideLink.replace(/.*\/\/[^\/]*/, '');
        }
    });
    return (
        <NewSlider autoPlay swiperUseEffect={swiper => swiperUseEffect(swiper)}>
            {slides.map((slide, index) => {
                if (slide.__typename == 'ContentfulSimpleVideoFile') {
                    return (
                        <StSliderHeroItem
                            key={`${slide.id}_${index}`}
                            slide
                            className="desktop-video"
                        >
                            <SlideWrapper>
                                <video
                                    width="100%"
                                    height="100%"
                                    nocontrols="false"
                                    controls
                                    autoPlay
                                    muted
                                    playsInline
                                    loop
                                    className="mobile-video"
                                >
                                    <source
                                        src={slide?.file2?.localFile?.publicURL}
                                        type="video/mp4"
                                    />
                                </video>
                                <video
                                    width="100%"
                                    height="100%"
                                    nocontrols="false"
                                    controls
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    className="desktop-video"
                                >
                                    <source
                                        src={slide?.file?.localFile?.publicURL}
                                        type="video/mp4"
                                    />
                                </video>
                            </SlideWrapper>
                        </StSliderHeroItem>
                    );
                }
                if (slide.__typename == 'ContentfulTripleColumnSlide') {
                    return (
                        <StSliderHeroItem key={`${slide.id}_${index}`} slide>
                            <SlideWrapper>
                                <SliderCol>
                                    {slide.leftImage &&
                                        slide.leftImage.localFile &&
                                        slide.leftImage.localFile.big &&
                                        slide.leftImage.localFile.big.fluid && (
                                            <SliderImageIn>
                                                <Img
                                                    fluid={get(
                                                        slide.leftImage,
                                                        'localFile.big.fluid'
                                                    )}
                                                    loading="eager"
                                                    imgStyle={{
                                                        opacity: 1,
                                                    }}
                                                    placeholderStyle={{
                                                        opacity: 0,
                                                    }}
                                                />
                                            </SliderImageIn>
                                        )}
                                </SliderCol>
                                <SliderCol>
                                    {slide.centerImage &&
                                        slide.centerImage.localFile &&
                                        slide.centerImage.localFile.big &&
                                        slide.centerImage.localFile.big
                                            .fluid && (
                                            <SliderImageIn>
                                                <Img
                                                    fluid={get(
                                                        slide.centerImage,
                                                        'localFile.big.fluid'
                                                    )}
                                                    loading="eager"
                                                    imgStyle={{
                                                        opacity: 1,
                                                    }}
                                                    placeholderStyle={{
                                                        opacity: 0,
                                                    }}
                                                />
                                            </SliderImageIn>
                                        )}
                                </SliderCol>
                                <SliderCol>
                                    <div className="slide-content-wrapper">
                                        <h4>{slide.title}</h4>
                                        <span>{slide.subtitle}</span>
                                        <p>{slide.description}</p>
                                        {slide.slideLink && (
                                            <Link to={slide.slideLink}>
                                                SHOP NOW
                                            </Link>
                                        )}
                                    </div>
                                </SliderCol>
                            </SlideWrapper>
                        </StSliderHeroItem>
                    );
                }

                if (slide.__typename == 'ContentfulSlide') {
                    return (
                        <StSliderHeroItem key={`${slide.id}_${index}`}>
                            {!slide.mobileImage && (
                                <StSliderHeroImg>
                                    <ConditionalWrapper
                                        condition={slide.slideLink}
                                        wrapper={children => (
                                            <Link to={slide.slideLink}>
                                                {children}
                                            </Link>
                                        )}
                                    >
                                        {slide.image &&
                                            slide.image.localFile &&
                                            slide.image.localFile.big &&
                                            slide.image.localFile.big.fluid && (
                                                <Img
                                                    fluid={get(
                                                        slide.image,
                                                        'localFile.big.fluid'
                                                    )}
                                                    loading="eager"
                                                    imgStyle={{
                                                        opacity: 1,
                                                    }}
                                                    placeholderStyle={{
                                                        opacity: 0,
                                                    }}
                                                />
                                            )}
                                    </ConditionalWrapper>
                                    {showText && (
                                        <StSliderHeroItemDescWeb
                                            textColor={
                                                slide.slideTextColor
                                                    ? slide.slideTextColor
                                                    : '#fff'
                                            }
                                        >
                                            {slide.slidePreText && (
                                                <h2 style={{ fontWeight: 600 }}>
                                                    {slide.slidePreText}
                                                </h2>
                                            )}
                                            <h2>{slide.slideText}</h2>
                                            {slide.slideLink && (
                                                <StLink invertColor>
                                                    <Link to={slide.slideLink}>
                                                        {slide.slideLinkText}
                                                    </Link>
                                                </StLink>
                                            )}
                                        </StSliderHeroItemDescWeb>
                                    )}
                                </StSliderHeroImg>
                            )}
                            {slide.mobileImage && (
                                <div>
                                    <StSliderHeroImgWeb>
                                        <ConditionalWrapper
                                            condition={slide.slideLink}
                                            wrapper={children => (
                                                <Link to={slide.slideLink}>
                                                    {children}
                                                </Link>
                                            )}
                                        >
                                            {slide.image &&
                                                slide.image.localFile &&
                                                slide.image.localFile.big &&
                                                slide.image.localFile.big
                                                    .fluid && (
                                                    <Img
                                                        fluid={get(
                                                            slide.image,
                                                            'localFile.big.fluid'
                                                        )}
                                                        loading="eager"
                                                        imgStyle={{
                                                            opacity: 1,
                                                        }}
                                                        placeholderStyle={{
                                                            opacity: 0,
                                                        }}
                                                    />
                                                )}
                                        </ConditionalWrapper>
                                        {showText && slide.slideText && (
                                            <StSliderHeroImgTextContainer>
                                                <StSliderHeroImgContainer>
                                                    <StSliderHeroItemSmallImgText>
                                                        {slide.smallImage && (
                                                            <ConditionalWrapper
                                                                condition={
                                                                    slide.slideLink
                                                                }
                                                                wrapper={children => (
                                                                    <Link
                                                                        to={
                                                                            slide.slideLink
                                                                        }
                                                                    >
                                                                        {
                                                                            children
                                                                        }
                                                                    </Link>
                                                                )}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={get(
                                                                        slide.smallImage,
                                                                        'localFile.publicURL'
                                                                    )}
                                                                />
                                                            </ConditionalWrapper>
                                                        )}
                                                    </StSliderHeroItemSmallImgText>
                                                </StSliderHeroImgContainer>
                                                <StSliderHeroImgText
                                                    textColor={
                                                        slide.slideTextColor
                                                            ? slide.slideTextColor
                                                            : '#fff'
                                                    }
                                                >
                                                    {slide.slidePreText && (
                                                        <h2
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {slide.slidePreText}
                                                        </h2>
                                                    )}
                                                    <h2>{slide.slideText}</h2>
                                                    {slide.slideLink && (
                                                        <StLink invertColor>
                                                            <Link
                                                                to={
                                                                    slide.slideLink
                                                                }
                                                            >
                                                                {
                                                                    slide.slideLinkText
                                                                }
                                                            </Link>
                                                        </StLink>
                                                    )}
                                                </StSliderHeroImgText>
                                            </StSliderHeroImgTextContainer>
                                        )}
                                        {showText && !slide.slideText && (
                                            <Container>
                                                <StSliderHeroItemDescSecond>
                                                    <StSliderHeroSmallImgWeb>
                                                        {slide.smallImage && (
                                                            <Link
                                                                to={
                                                                    slide.slideLink
                                                                }
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={get(
                                                                        slide.smallImage,
                                                                        'localFile.publicURL'
                                                                    )}
                                                                />
                                                            </Link>
                                                        )}
                                                    </StSliderHeroSmallImgWeb>
                                                </StSliderHeroItemDescSecond>
                                            </Container>
                                        )}
                                    </StSliderHeroImgWeb>
                                    <StSliderHeroImgMobile>
                                        <ConditionalWrapper
                                            condition={slide.slideLink}
                                            wrapper={children => (
                                                <Link to={slide.slideLink}>
                                                    {children}
                                                </Link>
                                            )}
                                        >
                                            {slide.mobileImage &&
                                                slide.mobileImage.localFile &&
                                                slide.mobileImage.localFile
                                                    .big &&
                                                slide.mobileImage.localFile.big
                                                    .fluid && (
                                                    <Img
                                                        fluid={get(
                                                            slide.mobileImage,
                                                            'localFile.big.fluid'
                                                        )}
                                                        loading="eager"
                                                        imgStyle={{
                                                            opacity: 1,
                                                        }}
                                                        placeholderStyle={{
                                                            opacity: 0,
                                                        }}
                                                    />
                                                )}
                                        </ConditionalWrapper>
                                    </StSliderHeroImgMobile>
                                </div>
                            )}
                            {(slide.mobileSlideText || slide.slideText) && (
                                <StSliderHeroItemDesc>
                                    <Container>
                                        <StSliderHeroItemDescFirst>
                                            <h2>
                                                {slide.mobileSlideText ||
                                                    slide.slideText}
                                            </h2>
                                            {slide.slideLink && (
                                                <StLink invertColor>
                                                    <Link to={slide.slideLink}>
                                                        {slide.slideLinkText ||
                                                            ' '}
                                                    </Link>
                                                </StLink>
                                            )}
                                        </StSliderHeroItemDescFirst>
                                        <StSliderHeroItemDescSecond>
                                            {slide.smallImage && (
                                                <ConditionalWrapper
                                                    condition={slide.slideLink}
                                                    wrapper={children => (
                                                        <Link
                                                            to={slide.slideLink}
                                                        >
                                                            {children}
                                                        </Link>
                                                    )}
                                                >
                                                    <img
                                                        alt=""
                                                        src={get(
                                                            slide.smallImage,
                                                            'localFile.publicURL'
                                                        )}
                                                    />
                                                </ConditionalWrapper>
                                            )}
                                        </StSliderHeroItemDescSecond>
                                    </Container>
                                </StSliderHeroItemDesc>
                            )}
                        </StSliderHeroItem>
                    );
                }
            })}
        </NewSlider>
    );
}
