import React from 'react';
import get from 'lodash.get';
import url from 'helpers/url';
import { useIntl, Link } from 'gatsby-plugin-react-intl';
import {
    SliderHero,
    SharedStyles,
    Container,
    NewTabs,
    Title,
} from '../../kenra-storybook/index';

const { StSectionMobileViewMore, StSectionTitle, StLink } = SharedStyles;

function getItems(desktopImages, mobileImages, href, subtitle) {
    return desktopImages.map((desktopImage, index) => {
        let desktopImageURL = get(desktopImage, 'localFile.publicURL');
        let mobileImageURL = get(mobileImages[index], 'localFile.publicURL');
        return {
            imgContent: {
                desktop: desktopImageURL,
                mobile: mobileImageURL || desktopImageURL,
            },
            imgThumbnail: desktopImageURL,
            href,
            subtitle,
        };
    });
}

export default function HomeColor(props) {
    let intl = useIntl();
    let discoverKenraColors = props.discoverKenraColors;
    let discoverKenraColorTitle = props.discoverKenraColorTitle;
    return (
        <StSectionMobileViewMore>
            <StSectionTitle>
                <Container>
                    <Title>{discoverKenraColorTitle}</Title>
                    <StLink mobileStyleLinkMore>
                        <Link to={url.colorList}>
                            {intl.formatMessage({ id: 'view-all-text' })}
                        </Link>
                    </StLink>
                </Container>
            </StSectionTitle>
            <NewTabs
                useTabs
                tabs={discoverKenraColors.map(group => group.tabTitle)}
                items={discoverKenraColors.map(group => group)}
            />
        </StSectionMobileViewMore>
    );
}
