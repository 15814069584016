import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash.get';
import { parseISO, format } from 'date-fns';

import url from 'helpers/url';
import { sortByCreatedAt } from 'helpers/sort';
import TOPICS from 'helpers/blogTopics';
import { getBlogPostUrl } from 'helpers/blogUrl';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { mapLocale } from 'helpers/mapLocale';

import {
    SharedStyles,
    Container,
    Articles,
    OurLatestArticles,
    Title,
    AllButton,
} from '../../kenra-storybook/index';

const { StSectionTitle, StLink } = SharedStyles;

const HomeArticles = ({ intl, latestArticlesTitle }) => {
    const {
        allContentfulBlogPost: { nodes },
    } = useStaticQuery(graphql`
        query {
            allContentfulBlogPost {
                nodes {
                    ...ContentfulBlogPostFields
                }
            }
        }
    `);

    let localeToCheck = mapLocale(intl.locale);
    let storedRegion = '';
    if (typeof window !== 'undefined') {
        storedRegion = localStorage.getItem('preferred-region');
    }

    let localeArticles = nodes.filter(node => {
        return node.node_locale === localeToCheck;
    });

    let articles = sortByCreatedAt(localeArticles)
        .filter(blog => {
            if (
                typeof blog.region === 'undefined' ||
                blog.region === null ||
                blog.region.length === 0 ||
                blog.region.includes(storedRegion)
            ) {
                return blog.archivedArticle ? false : true;
            } else {
                return false;
            }
        })
        .slice(0, 2);

    return (
        <section>
            <StSectionTitle>
                <Container>
                    <Title>{latestArticlesTitle}</Title>
                </Container>
            </StSectionTitle>
            <OurLatestArticles
                viewAll={
                    <AllButton href={url.blog} mobileHide>
                        {intl.formatMessage({ id: 'view-all-text' })}
                    </AllButton>
                }
                items={articles.map(item => ({
                    bg: get(item, 'image.localFile.publicURL'),
                    type: item.topic,
                    typeColor: get(TOPICS, `${item.topic}.color`),
                    title: item.title,
                    subtitle: `BY ${item.author}`,
                    // date: `posted ${format(parseISO(item.createdAt), 'd.MM.yyyy')}`,
                    linkText: intl.formatMessage({
                        id: 'blog-articles-read-post',
                    }),
                    linkHref: getBlogPostUrl(item),
                }))}
            />
        </section>
    );
};

export default injectIntl(HomeArticles);
