import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import get from 'lodash.get'
import url from 'helpers/url'

import {
    Spacing,
    Container,
    FindYourPerfectProduct,
} from '../../kenra-storybook/index'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { mapLocale } from 'helpers/mapLocale'

const HomeQuiz = ({ intl, findYourPerfectProductTitle }) => {
    const { allContentfulQuiz } = useStaticQuery(graphql`
        query {
            allContentfulQuiz {
                nodes {
                    ...ContentfulQuizFields
                }
            }
        }
    `)

    let localeToCheck = mapLocale(intl.locale)

    const quizList = []
    let quizzes = allContentfulQuiz.nodes
    var backgroundImage = ''

    if (process.env.GATSBY_ENV !== 'development') {
        quizzes = quizzes.filter(quiz => {
            return quiz.published
        })
    }

    quizzes.forEach(quiz => {
        if (quiz.node_locale === localeToCheck) {
            var description = documentToReactComponents(JSON.parse(quiz.description.raw || '{}'))
            backgroundImage = get(quiz.backgroundImage, 'localFile.publicURL')

            if (quiz.fullWidth) {
                quizList.unshift({
                    ...quiz,
                    text: description,
                    btnText: intl.formatMessage({ id: 'quiz-take-button' }),
                    href: url.quiz,
                    backgroundImage: backgroundImage,
                })
            } else {
                quizList.push({
                    ...quiz,
                    text: description,
                    btnText: intl.formatMessage({ id: 'quiz-take-button' }),
                    href: url.quiz,
                    backgroundImage: backgroundImage,
                })
            }
        }
    })

    const noQuizzes = !quizList || quizList.length <= 0

    return (
        <>
            {quizList && quizList.length > 0 && (
                <FindYourPerfectProduct
                    findYourPerfectProductTitle={findYourPerfectProductTitle}
                    items={quizList}
                />
            )}
            {noQuizzes && (
                <Spacing
                    style={{
                        backgroundImage: `url("${backgroundImage}")`,
                    }}
                >
                    <Container>
                        <h2 style={{ textAlign: 'center' }}>
                            {intl.formatMessage({ id: 'quiz-no-quizzes' })}
                        </h2>
                    </Container>
                </Spacing>
            )}
        </>
    )
}

export default injectIntl(HomeQuiz)
