import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import { TopReviews, Container, AllButton } from '../../kenra-storybook/index';

const {
    Title,
    ReviewsWrapper,
    Wrapper,
    Col,
    MainImage,
    StarsImg,
    ContentTitle,
    Content,
} = TopReviews;

const TopReviewsWrapper = ({ reviews, topReviewsTitle }) => {
    let intl = useIntl();
    const [currentReviews, setCurrentReviews] = useState([]);

    let storedRegion = 'US';
    if (typeof window !== 'undefined') {
        storedRegion = localStorage.getItem('preferred-region');
    }
    useEffect(() => {
        let topReviews = reviews.fragments.map((step, index) => {
            let returnData = {};
            step.forEach(fragmentData => {
                switch (fragmentData.key) {
                    case 'Image':
                        returnData['image'] = fragmentData.value;
                        break;
                    case 'Text':
                        returnData['text'] = fragmentData.value;
                        break;
                    case 'Title':
                        returnData['title'] = fragmentData.value;
                        break;
                    case 'Content':
                        returnData['content'] = fragmentData.value;
                        break;
                    case 'Author':
                        returnData['author'] = fragmentData.value;
                        break;
                    case 'Url':
                        returnData['url'] = fragmentData.value;
                        break;
                    case 'Stars':
                        returnData['stars'] = fragmentData.value;
                        break;
                    case 'Region':
                        returnData['region'] = fragmentData.value;
                        break;
                    default:
                        break;
                }
            });
            returnData['id'] = `${step.id}_${index}`;
            if (
                typeof returnData['region'] !== 'undefined' &&
                returnData['region'] !== null &&
                returnData['region'] !== '' &&
                returnData['region'] !== storedRegion
            ) {
                return null;
            }

            return returnData;
        });

        setCurrentReviews(topReviews);
    }, reviews.fragments);

    return (
        <Container>
            <Title>{topReviewsTitle}</Title>

            <ReviewsWrapper>
                {currentReviews.map(review => {
                    if (review === null) {
                        return null;
                    }
                    return (
                        <Wrapper key={review.id}>
                            <Col className="image-side">
                                <MainImage
                                    id={review.id}
                                    src={review.image}
                                    alt="Image"
                                />
                            </Col>
                            <Col className="content">
                                {review.stars && (
                                    <StarsImg src={review.stars} />
                                )}
                                <ContentTitle>{review.title}</ContentTitle>
                                <Content>
                                    {review.content}
                                    <span>{review.author}</span>
                                    <AllButton href={review.url}>
                                        {intl.formatMessage({
                                            id: 'kenra-shop-now',
                                        })}
                                    </AllButton>
                                </Content>
                            </Col>
                        </Wrapper>
                    );
                })}
            </ReviewsWrapper>
        </Container>
    );
};

export default TopReviewsWrapper;
