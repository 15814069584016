var getBlogPostUrl = function(blog) {
    const { title, url } = blog;
    if (url && url.length > 0) {
        return `/blog/${url}`;
    } else {
        return `/blog/${encodeURI(title.toLowerCase().replace(/\s/g, '-'))
            .replace('&', 'and')
            .replace(':', '')
            .replace('-%7C', '')}`;
    }
};

module.exports.getBlogPostUrl = getBlogPostUrl;
