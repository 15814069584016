import React from 'react';

import { Spacing, WhatWeStandFor } from '../../kenra-storybook/index';

import HeroSlider from './HeroSlider';
import HomeVideo from './HomeVideo';
import HomeColor from './HomeColor';
import TopReviewsWrapper from './TopReviewsWrapper';
import HomeQuiz from './HomeQuiz';
import BestSellerSlider from './BestSellerSlider';
import HomeArticles from './HomeArticles';
class Home extends React.Component {
    render() {
        let {
            page,
            allShopifyProducts,
            allGoogleSheetProductsRow,
        } = this.props;
        return (
            <>
                <HeroSlider slides={page.heroSlides} showText />
                <BestSellerSlider
                    title={page.stylistFavoritesTitle}
                    subtitle={page.stylistFavoritesSubtitle}
                    link={page.stylistFavoritesLink}
                    products={page.stylistFavoritesProducts}
                    shopifyProducts={allShopifyProducts}
                    allGoogleSheetProductsRow={allGoogleSheetProductsRow}
                />

                <Spacing>
                    <HomeColor
                        discoverKenraColorTitle={page.discoverKenraColorTitle}
                        discoverKenraColors={page.discoverKenraColors}
                    />
                </Spacing>

                <Spacing>
                    <TopReviewsWrapper
                        topReviewsTitle={page.topReviewsTitle}
                        reviews={page.reviews}
                    />
                </Spacing>

                <Spacing>
                    <HomeVideo
                        watchOurTutorialsTitle={page.watchOurTutorialsTitle}
                        tutorialVideo={page.tutorialVideo}
                        secondTutorialVideo={page.secondTutorialVideo}
                    />
                </Spacing>

                <Spacing>
                    <HomeQuiz
                        findYourPerfectProductTitle={
                            page.findYourPerfectProductTitle
                        }
                    />
                </Spacing>
                <Spacing>
                    <HomeArticles
                        latestArticlesTitle={page.latestArticlesTitle}
                    />
                </Spacing>

                <Spacing>
                    <WhatWeStandFor
                        whatWeStandForTitle={page.whatWeStandForTitle}
                        icons={page.whatWeStandFor}
                    />
                </Spacing>
            </>
        );
    }
}

export default Home;
