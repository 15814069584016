import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import NewHome from 'components/NewHome';
import SEO from 'components/seo';
import {
    isChrome,
    isEdge,
    isBrowser,
    isSafari,
    isFirefox,
    isIE,
} from 'react-device-detect';
import get from 'lodash.get';

export default function IndexPage(props) {
    let {
        data: {
            contentfulNewHomePage,
            allShopifyProduct: { nodes: allShopifyProducts },
            allGoogleSheetProductsRow,
        },
    } = props;
    let browserString = `isBrowser: ${isBrowser}, isChrome: ${isChrome}, isEdge: ${isEdge}, isIE: ${isIE}`;
    let navigatorData = 'NOT YET DEFINED';
    if (typeof navigator !== 'undefined') {
        navigatorData = navigator.userAgent;
    }
    return (
        <Layout>
            <SEO
                description={get(
                    contentfulNewHomePage,
                    'seoListing.description.description'
                )}
                title={get(contentfulNewHomePage, 'seoListing.seoTitle')}
                slug={'default'}
            />
            <NewHome
                page={contentfulNewHomePage}
                allShopifyProducts={allShopifyProducts}
                browserData={browserString}
                navigatorData={navigatorData}
                allGoogleSheetProductsRow={allGoogleSheetProductsRow}
            ></NewHome>
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulNewHomePage(node_locale: { eq: $locale }) {
            heroSlides {
                __typename
                ...SlideFields
                ...TripleSlideFields
                ...ContentfulSimpleVideoFileFields
            }
            reviews {
                fragments {
                    key
                    value
                    type
                }
            }
            discoverKenraColors {
                ...ColorFields
            }
            whatWeStandFor {
                fragments {
                    key
                    value
                    type
                }
            }
            stylistFavoritesTitle
            stylistFavoritesSubtitle
            stylistFavoritesLink
            stylistFavoritesProducts {
                ...ContentfulProductFields
            }
            watchOurTutorialsTitle
            findYourPerfectProductTitle
            latestArticlesTitle
            discoverKenraColorTitle
            topReviewsTitle
            whatWeStandForTitle

            tutorialVideo {
                ...ContentfulVideoFields
            }
            secondTutorialVideo {
                ...ContentfulVideoFields
            }
            node_locale
            seoListing {
                ...ContentfulSeoListingFields
            }
        }
        allShopifyProduct {
            nodes {
                ...ShopifyProductFields
            }
        }
        allGoogleSheetProductsRow {
            nodes {
                sku
                caAssortment
                canIdh
            }
        }
    }
`;
