import React from 'react';

import url from 'helpers/url';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { injectIntl } from 'gatsby-plugin-react-intl';

import {
    Container,
    NewVideoWithDesc,
    SharedStyles,
} from '../../kenra-storybook/index';

const { VidTitle } = SharedStyles;

const HomeVideo = ({
    intl,
    tutorialVideo,
    secondTutorialVideo,
    watchOurTutorialsTitle,
}) => {
    let youtubeLink = tutorialVideo.youtubeLink;
    let youtubeLink2 = secondTutorialVideo.youtubeLink;

    let youTubeId = getYouTubeId(youtubeLink);
    let youTubeId2 = getYouTubeId(youtubeLink2);

    return (
        <section>
            <div>
                <Container>
                    <VidTitle>{watchOurTutorialsTitle}</VidTitle>
                </Container>
            </div>

            <NewVideoWithDesc
                text={tutorialVideo.title.title}
                text2={secondTutorialVideo.title.title}
                linkMoreText={intl.formatMessage({ id: 'video-view-all' })}
                linkMoreHref={url.videos}
                bgImg={getPreviewUrl(youTubeId)}
                bgImg2={getPreviewUrl(youTubeId2)}
                videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
                videoOptions2={[{ src: getEmbedUrl(youTubeId2) }]}
            />
        </section>
    );
};

export default injectIntl(HomeVideo);
